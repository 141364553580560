import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OrdersTable from "./OrdersTable";
import PricesTable from "./PricesTable";
import Bar from "./Bar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    position: "relative",
    minHeight: 200,
    margin: "0 auto"
  },
  subtable: {
    "margin-left": 30,
    backgroundColor: "#ffe9e9"
  }
}));

function App() {
  var CLIENT_ID = '538531819217-bn106pb9fppna4sm6fnmr1mot2k4ddp9.apps.googleusercontent.com';
  var API_KEY = 'AIzaSyBv8bTt_pIMDYkjFzT5InTTVugQ4M-OSAU';
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
  var SCOPES = 'https://www.googleapis.com/auth/drive.appfolder';

  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/client.js";
  script.onload = () => {
    console.log("ENV", process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development') {
      if (!stateG.user) {
        var data = require('./mock.json');
        setState(data);
        setStateG({
          auth: 1,
          user: {
            name: 'dev',
            email: 'dev',
          }
        });
      }
  } else {
    window.gapi.load('client:auth2', initClient);
  }
  };
  document.body.appendChild(script);

  const classes = useStyles();
  const theme = useTheme();
  const [curentTab, setCurentTab] = React.useState({
    value: 0
  });
  const [stateG, setStateG] = React.useState({
    auth: 0,
    user: null
  });
  const [fileID, setFileID] = React.useState({
    id: ""
  });
  const [state, setState] = React.useState({
    orders: [

    ],
    prices: [

    ]
  });






  function initClient() {
    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    }).then(async function () {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

      if (!stateG.auth && window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
        let user = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
      setStateG({
        auth: 1,
        user: {
          avatar: user.getImageUrl(),
          name: user.getName(),
          email: user.getEmail(),
        }
      });
        if (fileID.id !== "") { } else {
          let fileId = await getFileId();
          let data = await download(fileId);
          setState(data);
        };
      }
    }, function (error) {
      console.log(JSON.stringify(error, null, 2));
    });
  }
  async function updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      let user = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
      setStateG({
        auth: 1,
        user: {
          avatar: user.getImageUrl(),
          name: user.getName(),
          email: user.getEmail(),
        }
      });
      if (fileID.id !== "") { } else {
        let fileId = await getFileId();
        let data = await download(fileId);
        setState(data);
      };
    } else {
      setStateG({
        auth: 0,
        user: null
      });
    }
  }
  async function getFileId() {
    console.log("getFileId");
    const resp = await prom(window.gapi.client.drive.files.list, {
      spaces: 'appDataFolder',
      q: "name='config.json'",
      pageSize: 10,
      fields: "files(id, name)"
    });
    var files = resp.result.files;
    if (files && files.length > 0 && files[0].id) {
      setFileID({ id: files[0].id });

      return files[0].id;
    } else {
      let newF = await newFile();
      setFileID({ id: newF });

      return newF;
    }
  }


  async function newFile() {
    var fileMetadata = {
      name: 'config.json',
      mimeType: 'application/json',
    };
    const resp = await prom(window.gapi.client.drive.files.create, {
      parents: ['appDataFolder'],
      name: 'config.json',
      mimeType: 'application/json',
      resource: fileMetadata,
      media: fileMetadata,
    });
    return resp.result.id;
  }

  // {
  //   "orders": [
  //     {
  //       "date": "2019-06-23",
  //       "pm": "p1",
  //       "name": "Ð¤Ð¾ÑÐ¼ÑÐ»Ð°",
  //       "id": null,
  //       "tableData": {
  //         "id": 0
  //       },
  //       "positions": [
  //         {
  //           "pm": "p1",
  //           "patient": "Ð ÐµÑÐµÑÐ¸Ð»Ð¾",
  //           "amount": "5",
  //           "item": "ÐÐ¾ÑÐ¾Ð½ÐºÐ° Ð¼Ðº",
  //           "price": "260",
  //           "total": 1300,
  //           "corder": "97",
  //           "tableData": {
  //             "id": 0
  //           }
  //         },

  async function upload(content) {
    if (process.env.NODE_ENV === 'development') return 1
    console.log("ZZZZ", JSON.stringify(content));

    if (content.orders) {
      content.orders = content.orders.map((item) => {
        if (item.positions) {
          item.positions.sort((a, b) => {
            return a.corder - b.corder;
          });
          item.positions.forEach(e => {
            delete e.tableData;
          });
        };
        return item;
      });
      console.log("QQQQ", JSON.stringify(content));
    }
    setState(content);
    let fileId = fileID.id;
    // функция принимает либо строку, либо объект, который можно сериализовать в JSON
    return prom(window.gapi.client.request, {
      path: `/upload/drive/v3/files/${fileId}`,
      method: 'PATCH',
      params: { uploadType: 'media' },
      body: typeof content === 'string' ? content : JSON.stringify(content)
    })
  }

  async function download(fileId) {
    console.log("download");

    const resp = await prom(window.gapi.client.drive.files.get, {
      fileId: fileId,
      alt: 'media'
    });
    return resp.result || { orders: [], prices: [] };
  }

  function prom(gapiCall, argObj) {
    return new Promise((resolve, reject) => {
      gapiCall(argObj).then(resp => {
        if (resp && (resp.status < 200 || resp.status > 299)) {
          console.log('gapi call returned bad status', resp)
          reject(resp)
        } else {
          resolve(resp)
        }
      }, err => {
        console.log('gapi call failed', err)
        reject(err)
      })
    })
  }

  function handleAuthClick(event) {
    window.gapi.load('client:auth2', initClient);
    window.gapi.auth2.getAuthInstance().signIn();
  }

  function handleSignoutClick(event) {
    window.gapi.auth2.getAuthInstance().signOut();

  }





  function handleChange(event, value) {
    setCurentTab({ ...curentTab, value });
  }


  return (
    <div className={classes.root}>
      <Bar
        auth={stateG.auth} user={stateG.user}
        login={handleAuthClick}
        logout={handleSignoutClick} />
      {stateG.auth ? (<div>
        <AppBar position="static" color="default">
          <Tabs
            value={curentTab.value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Orders" />
            <Tab label="Prices" />
          </Tabs>

        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={curentTab.value}
          disabled={true}
        >
          <OrdersTable state={state} setstate={upload} />
          <PricesTable state={state} setstate={upload} />
        </SwipeableViews> </div>) : null}
    </div>
  );
}

export default App;
