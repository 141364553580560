function NumToText(_number) {
    function number_parser(_num, _desc) {
        let _arr_numbers = [
            ['', 'один', 'два', 'три', 'чотири', 'п\'ять', 'шість', 'сім', 'вісім', 'дев\'ять', 'десять', 'одинадцять', 'дванадцять', 'тринадцять', 'чотирнадцять', 'п\'ятнадцять', 'шістнадцять', 'сімнадцять', 'вісімнадцять', 'дев\'ятнадцять'],
            ['', '', 'двадцять', 'тридцять', 'сорок', 'п\'ятдесят', 'шістдесят', 'сімдесят', 'вісімдесят', 'дев\'яносто'],
            ['', 'сто', 'двісті', 'триста', 'чотириста', 'п\'ятсот', 'шістсот', 'сімсот', 'вісімсот', 'дев\'ятсот']
        ];
        let _string = '';
        let teen = false;
        if (_num > 10 && _num < 20) teen = true
        if (_num.length === 3) {
            let _num_hundred = _num.substr(0, 1);
            _num = _num.substr(1, 3);
            _string = _arr_numbers[2][_num_hundred] + ' ';
        }
        if (_num < 20) _string += _arr_numbers[0][parseFloat(_num)] + ' ';
        else {
            _string += _arr_numbers[1][_num.substr(0, 1)] + ' ' + _arr_numbers[0][ _num.substr(1, 2)] + ' ';
        }
        let _last_num = parseFloat(_num.substr(-1));
        switch (_desc){
            case 0:
                _string += ' грн.';
                _string = _string.replace('один ', 'одна ');
                _string = _string.replace('два ', 'дві ');
                break;
            case 1:
                if (_last_num === 1 && !teen) _string += 'тисяча ';
                else if (_last_num > 1 && _last_num < 5 && !teen) _string += 'тисячі ';
                else _string += 'тисяч ';
                _string = _string.replace('один ', 'одна ');
                _string = _string.replace('два ', 'дві ');
                break;
            case 2:
                if (_last_num === 1 && !teen) _string += 'мільйон ';
                else if (_last_num > 1 && _last_num < 5 && !teen) _string += 'мільйона ';
                else _string += 'мільйонів ';
                break;
            case 3:
                if (_last_num === 1 && !teen) _string += 'мільярд ';
                else if (_last_num > 1 && _last_num < 5 && !teen) _string += 'мільярда ';
                else _string += 'мільярдів ';
                break;
            default: 
                break;
        }
        _string = _string.replace('  ', ' ');
        return _string;
    }
    if (!_number) return false;
    if (typeof _number !== 'number') {
        _number = _number.replace(',', '.');
        _number = parseFloat(_number);
        if (isNaN(_number)) return false;
    }
    _number = _number.toFixed(2);
    let _number_decimals = _number.split('.')[1];
    _number = _number.split('.')[0];

    let _string = '';
    let _trinity = '';
    let _trinity_count = 0;
    for (let _p = (_number.length - 1); _p >= 0; _p--) {
        _trinity = _number.substr(_p, 1) + _trinity;
        
        if ((_trinity.length === 3 || _p === 0) && !isNaN(parseFloat(_trinity))) {
            _string = number_parser(_trinity, _trinity_count) + _string;
            _trinity = '';
            _trinity_count++;
        }
    }
    if (_number_decimals) _string += ' ' + _number_decimals.substr(0, 1) + parseFloat(_number_decimals.substr(1, 2)) + ' коп.';
    return _string;
}

export default NumToText;
