import React from "react";
import MaterialTable from "material-table";

function PricesTable(props) {
    const state = props.state;
    const setState = props.setstate;
    const columns =
        [
            { title: "Name", field: "name" },
            { title: "Price1", field: "p1" },
            { title: "Price2", field: "p2" }
        ];
    return (
        <MaterialTable
            options={{
                addRowPosition: "first",
                showTitle: false,
                search: true,
                actionsColumnIndex: -1
            }}
            initialFormData={{
                date: new Date().toISOString().split("T")[0],
                pm: "p1"
            }}
            columns={columns}
            data={state.prices}
            editable={{
                onRowAdd: newData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = { ...state };
                            data.prices.push(newData);
                            setState({ ...state, ...data });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = { ...state };
                            data.prices[data.prices.indexOf(oldData)] = newData;

                            setState({ ...state, ...data });
                        }, 600);
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = { ...state };
                            data.prices.splice(data.prices.indexOf(oldData), 1);
                            setState({ ...state, ...data });
                        }, 600);
                    })
            }}
        />
    );

}

export default PricesTable;
