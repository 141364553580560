import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';
import { CardContent, Card, CardActions, Divider } from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
  },
  cardcontent: {
    display: 'flex',
  },
  avatar: {
    padding:0,
    margin: 0,
  },
  bigAvatar: {
    marginRight: 20,
    width: 96,
    height: 96,
  },
  accountM: {
    fontWeight: 'bold',
    fontSize: 'small',
    fontFamily: "'Roboto',sans-serif"
  },
  accountS: {
    fontSize: 'small',
    color: '#666',
    fontFamily: "'Roboto',sans-serif"
  },
  accountL: {
    fontSize: 'small',
    fontFamily: "'Roboto',sans-serif",
    margin: '6px 0',
  },
  Gbutton:
  {
    margin: "10px 0 0 0",
    textTransform: "none",
    fontWeight: 'normal'

  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    background: "#f5f5f5",
  }
}));

function Bar(props) {
  const classes = useStyles();
  const auth=props.auth;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Have a nice day ;)
                    </Typography>
          {auth ? (
            <div>
              <Tooltip title={props.user.name}>
                <IconButton
                  aria-label="Account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  className={classes.avatar}
                >
                  <Avatar src={props.user.avatar} className={classes.avatar} />
                </IconButton>
              </Tooltip>
              <Popover anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose} open={open} anchorEl={anchorEl} >

                <Card>
                  <CardContent className={classes.cardcontent}>
                    <Avatar  src={props.user.avatar} className={classes.bigAvatar} />
                    <div>
                      <div className={classes.accountM}>
                       {props.user.name}
                      </div>
                      <div className={classes.accountS}>
                        {props.user.email}
                      </div>
                      <div>
                        <Link className={classes.accountL} href="https://myaccount.google.com/privacypolicy"> Конфиденциальность </Link>
                      </div>
                      <Button variant="contained" size="small" color="primary" href="https://myaccount.google.com" className={classes.Gbutton}>Обліковий запис Google</Button>

                    </div>
                  </CardContent>
                  <Divider light />
                  <CardActions className={classes.cardAction}>
                    <div>
                      <Button variant="outlined" size="small" className={classes.Gbutton} href="https://accounts.google.com/AddSession">Додати обліковий запис</Button>
                    </div>
                    <div>
                    <Button variant="outlined" size="small" onClick={()=>{props.logout(); setAnchorEl(null);}} className={classes.Gbutton}>Вийти</Button>
                    </div>
                  </CardActions>
                </Card>

              </Popover>
            </div>
          ) : (
              <Button onClick={props.login} variant="contained" >
                Увійти
                            </Button>
            )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Bar;
