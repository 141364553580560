import React from "react";
import { Icon, IconButton, Tooltip, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumToText from './NumToText';
// in your test:



const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    'border-collapse': 'collapse',
    '& td': {
      border: '1px solid black'
    },
    '& th': {
      border: '1px solid black'
    }
  }
}));

function ShowTableButton(data) {

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCopied(false);
    setOpen(false);
  };
  const [copied, setCopied] = React.useState(false);
    if (!copied) {
    var checkExist =  setInterval(() => {
      if (document.getElementById('modal-table') && document.getElementById('modal-table').children.length) {
        var range;
        if (document.selection) { // IE
          range = document.body.createTextRange();
          range.moveToElementText(document.getElementById('modal-table'));
          range.select();
        } else if (window.getSelection) {
          range = document.createRange();
          range.selectNode(document.getElementById('modal-table'));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
        document.execCommand("copy");
        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        }
        setCopied(true);        clearInterval(checkExist);
      }
    }, 200);
  }

  return (<React.Fragment>
    <Tooltip title="Show Table">
      <IconButton
        onClick={handleOpen}
      >
        <Icon>table_view</Icon>
      </IconButton>
    </Tooltip>
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
    >    
      <div id="modal-table" className={classes.paper}>
        <br/>
        <Tooltip title="The table has been copied to the clipboard." open={true} >
          <table className={classes.table}>
            <thead><tr><th>№ наряду</th><th>П.І.Б. пацієнта</th><th>Найменування</th><th>Ціна</th><th>Кількість</th><th>Сума</th></tr></thead>
            <tbody>{Object.entries(data.positions ? data.positions : {} ).map((e, i) => {
              return (<tr key={i}><td style={{textAlign: 'right'}}>{e[1].corder}</td><td>{e[1].patient}</td><td>{e[1].item}</td><td style={{textAlign: 'right'}}>{e[1].price}</td><td style={{textAlign: 'right'}}>{e[1].amount}</td><td style={{textAlign: 'right'}}>{e[1].total}</td></tr>)
            })}</tbody>
            <tfoot><tr><td colSpan={5}>Всього на суму: {NumToText(data.total)}</td><td>{data.total}</td></tr></tfoot>
          </table>
        </Tooltip>
        <br/>
      </div> 
    </Modal>
  </React.Fragment>

  );
}
export default ShowTableButton;
