import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Select } from "@material-ui/core";
import MaterialTable, { MTableActions } from "material-table";
import PrintPDFButton from "./PrintPDFButton";
import ShowTableButton from "./ShowTableButton";
import ShowSummaryButton from "./ShowSummaryButton";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
function OrdersTable(props) {
    const state = props.state;
    const setState = props.setstate;
    const classes = makeStyles(theme => ({
        subtable: {
            "margin-left": 30,
            backgroundColor: "#ffe9e9"
        }
    }));

    function getPrice(value, pm) {
        const price = state.prices.filter(x => x.name === value)[0];
        return price[pm] ? price[pm] : price["p1"];
    }
    const dcolumns = [
        { 
            title: "Order #",
            field: "corder", 
            editComponent: props => {
                return (
                    <TextField
                        type={"number"}
                        autoFocus={true}
                        placeholder={props.columnDef.title}
                        value={props.value === undefined ? "" : props.value}
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.corder = e.target.value;
                            props.onRowDataChange(data);
                        }}
                    />
                );
            }
        },
        { title: "Patient name", field: "patient" },
        {
            title: "Item",
            field: "item",
            editComponent: props => {
                return (
                    <Select
                        native
                        value={props.value}
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.item = e.target.value;
                            data.price = getPrice(e.target.value, data.pm);
                            data.total = data.price * data.amount;
                            props.onRowDataChange(data);
                        }}
                    >
                        <option />
                        {state.prices.map(x => {
                            return <option key={x.name}>{x.name}</option>;
                        })}
                    </Select>
                );
            }
        },
        {
            title: "Price",
            field: "price",
            editComponent: props => {
                return (
                    <TextField
                        type={"number"}
                        placeholder={props.columnDef.title}
                        value={props.value === undefined ? "" : props.value}
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.price = e.target.value;
                            data.total = data.price * data.amount;
                            props.onRowDataChange(data);
                        }}
                    />
                );
            }
        },
        {
            title: "Amount",
            field: "amount",
            editComponent: props => {
                return (
                    <TextField
                        type={"number"}
                        placeholder={props.columnDef.title}
                        value={props.value === undefined ? "" : props.value}
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.amount = e.target.value;
                            data.total = data.price * data.amount;
                            props.onRowDataChange(data);
                        }}
                    />
                );
            }
        },
        { title: "Subtotal", field: "total", editable: "never" }
    ];
    const ocolumns = [
        { title: "Name", field: "name" },
        {
            title: "Date",
            field: "date",
            editComponent: props => {
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            format="dd.MM.yyyy"
                            value={props.value || null}
                            onChange={e => {
                                var data = { ...props.rowData };
                                data.date = e.toISOString().split("T")[0];
                                props.onRowDataChange(data);
                            }}
                            clearable
                            InputProps={{
                                style: {
                                    fontSize: 13
                                }
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            }
        },
        {
            title: "Price",
            field: "pm",
            editComponent: props => {
                return (
                    <Select
                        native
                        value={props.value}
                        onChange={e => {
                            var data = { ...props.rowData };
                            data.pm = e.target.value;

                            props.onRowDataChange(data);
                        }}
                    >
                        <option value="p1">1</option>;<option value="p2">2</option>;
            </Select>
                );
            }
        },
        { title: "Total", field: "total", editable: "never" }
    ];
    return (
        <MaterialTable
            options={{
                addRowPosition: "first",
                showTitle: false,
                search: true,
                actionsColumnIndex: -1
            }}
            initialFormData={{
                date: new Date().toISOString().split("T")[0],
                pm: "p1"
            }}
            components={{
                Actions: props => {
                    if (props.data) {

                        return (
                            <div style={{ display: "flex" }}>
                                <MTableActions {...props} />
                                <PrintPDFButton {...props.data} />
                                <ShowTableButton {...props.data} />
                                <ShowSummaryButton {...props.data} />
                            </div>
                        );
                    } else {
                        return <MTableActions {...props} />;
                    }
                }
            }}
            columns={ocolumns}
            data={state.orders}
            detailPanel={
                rowData => {
                    return (
                        <div className={classes.subtable}>
                            <MaterialTable
                                options={{
                                    addRowPosition: "first",
                                    showTitle: false,
                                    search: false,
                                    paging: false,
                                    pageSize: 100,
                                    actionsColumnIndex: -1,
                                    headerStyle: { backgroundColor: "#ffe9e9" },
                                    rowStyle: { backgroundColor: "#ffe9e9" }
                                }}
                                initialFormData={{
                                    pm: rowData.pm,
                                    patient: "",
                                    amount: 1,
                                    item: "",
                                    price: null,
                                    total: null
                                }}
                                columns={dcolumns}
                                data={rowData.positions ? rowData.positions : []}
                                pData={rowData}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = rowData;
                                                data.positions = data.positions
                                                    ? data.positions
                                                    : [];
                                                data.positions.push(newData);
                                                data.total = 0;
                                                data.positions.forEach(p => {
                                                    data.total = data.total + p.total;
                                                });
                                                setState({ ...state, ...data });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = rowData;
                                                data.positions[
                                                    data.positions.indexOf(oldData)
                                                ] = newData;
                                                data.total = 0;
                                                data.positions.forEach(p => {
                                                    data.total = data.total + p.total;
                                                });
                                                setState({ ...state, ...data });
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = rowData;
                                                data.positions.splice(
                                                    data.positions.indexOf(oldData),
                                                    1
                                                );
                                                data.total = 0;
                                                data.positions.forEach(p => {
                                                    data.total = data.total + p.total;
                                                });
                                                setState({ ...state, ...data });
                                            }, 600);
                                        })
                                }}
                            />
                        </div>
                    );
                }
            }
            editable={{
                onRowAdd: newData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = { ...state };
                            const id = Math.max(...state.orders.map(o => o.id)) + 1;
                            newData.id = id;
                            data.orders.push(newData);
                            setState({ ...state, ...data });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = { ...state };
                            if (oldData.pm !== newData.pm) {
                                newData.positions.forEach(element => {
                                    const incex = newData.positions.indexOf(element);
                                    newData.positions[incex].pm = newData.pm;
                                    newData.positions[incex].price = getPrice(
                                        element.item,
                                        newData.pm
                                    );
                                    newData.positions[incex].total =
                                        newData.positions[incex].price *
                                        newData.positions[incex].amount;
                                });
                            }

                            data.orders[data.orders.indexOf(oldData)] = newData;

                            setState({ ...state, ...data });
                        }, 600);
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = { ...state };
                            data.orders.splice(data.orders.indexOf(oldData), 1);
                            setState({ ...state, ...data });
                        }, 600);
                    })
            }}
        />
    );
}

export default OrdersTable;
