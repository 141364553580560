import React from "react";
import { Icon, IconButton, Tooltip, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumToText from './NumToText';
// in your test:

const reducer = (acc, cur, i, a) => {
  acc[cur.item] = {
    item: cur.item,
    amount: (acc[cur.item]) ? acc[cur.item].amount + parseInt(cur.amount) : 0 + parseInt(cur.amount),
    price: cur.price,
    total: (acc[cur.item]) ? acc[cur.item].total + parseInt(cur.total) : 0 + parseInt(cur.total)
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    'border-collapse': 'collapse',
    '& td': {
      border: '1px solid black'
    },
    '& th': {
      border: '1px solid black'
    }
  }
}));

function ShowSummaryButton(data) {

  let r = {};
  if (data && data.positions) {
    r = data.positions.reduce(reducer, {});
  }
  var sum = 0;
  for (const i in r) {
    sum += r[i].total;
  }
  sum = sum.toFixed(2);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCopied(false);
    setOpen(false);
  };
  const [copied, setCopied] = React.useState(false);
    if (!copied) {
    var checkExist =  setInterval(() => {
      if (document.getElementById('modal-table') && document.getElementById('modal-table').children.length) {
        var range;
        if (document.selection) { // IE
          range = document.body.createTextRange();
          range.moveToElementText(document.getElementById('modal-table'));
          range.select();
        } else if (window.getSelection) {
          range = document.createRange();
          range.selectNode(document.getElementById('modal-table'));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
        document.execCommand("copy");
        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        }
        setCopied(true);        clearInterval(checkExist);
      }
    }, 200);
  }

  return (<React.Fragment>
    <Tooltip title="Show Summary">
      <IconButton
        onClick={handleOpen}
      >
        <Icon>table_chart</Icon>
      </IconButton>
    </Tooltip>
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
    >
      <div id="modal-table" className={classes.paper}>
        <br />
        <Tooltip title="The table has been copied to the clipboard." open={true} >
          <table className={classes.table}>
            <thead><tr><th>№п/п</th><th>Вид роботи</th><th>Од.</th><th>К-сть</th><th>Ціна</th><th>Всього</th></tr></thead>
            <tbody>{Object.entries(r).map((e, i) => {
              return (<tr key={i}><td style={{textAlign: 'right'}}>{i + 1}</td><td>{e[0]}</td><td>шт.</td><td style={{textAlign: 'right'}}>{e[1].amount}</td><td style={{textAlign: 'right'}}>{e[1].price}</td><td style={{textAlign: 'right'}} >{e[1].total}</td></tr>)
            })}</tbody>
            <tfoot><tr><td colSpan={5}>Всього на суму: {NumToText(sum)}</td><td>{sum}</td></tr></tfoot>
          </table>
        </Tooltip>
        <br />
      </div>
    </Modal>
  </React.Fragment>

  );
}
export default ShowSummaryButton;
